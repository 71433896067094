<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar size="large" class="primary" icon="pi pi-plus" />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New Programme Cohort
                    </div>
                    <div class="text-sm text-500">
                      Create new programme cohort record
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-9 sm:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12 md:col-12">
                      <div class="mb-2 font-bold text-sm">
                        Programme Cohort Name *
                      </div>
                      <div id="ctrl-programme_cohort_name-holder">
                        <InputText
                          ref="ctrlprogramme_cohort_name"
                          v-model.trim="formData.programme_cohort_name"
                          label="Programme Cohort"
                          type="text"
                          placeholder="Enter Programme Cohort Name"
                          class="w-full"
                          :class="getErrorClass('programme_cohort_name')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">
                        Programme Description
                      </div>
                      <div id="ctrl-programme_cohort_description-holder">
                        <Textarea
                          :class="getErrorClass('programme_cohort_description')"
                          class="w-full"
                          ref="ctrlprogramme_cohort_description"
                          rows="2"
                          v-model="formData.programme_cohort_description"
                          placeholder=" Programme Cohort Description"
                          type="textarea"
                        >
                        </Textarea>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import {
  decimal,
  email,
  integer,
  ipAddress,
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";
import { AddPageMixin } from "../../mixins/addpage.js";
import { PageMixin } from "../../mixins/page.js";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addCapsprogrammescohortsPage",
  components: {},
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "caps_programme_cohorts",
    },
    routeName: {
      type: String,
      default: "caps_programmes_cohortadd",
    },
    apiPath: {
      type: String,
      default: "caps_programme_cohorts/add",
    },
  },
  data() {
    return {
      formData: {
        programme_cohort_name: "",
        programme_cohort_description: "",
      },
      end_datePicker: false,
      start_datePicker: false,
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add Programme";
      },
    },
  },
  validations() {
    let formData = {
      programme_cohort_name: { required },
      programme_cohort_description: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("caps_programme_cohorts", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["caps_programmes_cohort_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          // if (this.redirect) this.navigateTo(`/caps_programme_cohorts`);
          this.$router.push(this.$route.path);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    updateFormFields: function () {
      //update form fields value after load from api
      //e.g convert fieldvalue (value,value2,value2) to array
    },
    resetForm() {
      this.formData = {
        programme_cohort_name: "",
        programme_cohort_description: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
